import React, { Component } from 'react';

import 'components/Menu.scss';

class Menu extends Component {
    render() {
        return (
            <nav id="main-nav">
                <ul>
                {this.props.modele.menu.map((s,i)=>{
                    let item=<li key={i} className="pointer" onClick={()=>this.props.goTo(s.url)}>{s.titre}</li>;
                    if (s.url==='/videos/' && this.props.modele.videos.length===0) item='';
                    if (s.url==='/action-culturelle/' && this.props.modele.actions.length===0) item='';
                    if (s.url==='/blog/' && this.props.modele.billets.length===0) item='';
                    if (s.url==='/agenda/' && this.props.modele.evts.length===0) item='';
                    return item;
                })}
                </ul>
            </nav>
        );
    }
}
export default Menu;
