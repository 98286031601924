import React, { Component } from 'react';
import 'index.css';
import 'components/Son.scss';
import PlayerAudio from 'components/PlayerAudio';

class Son extends Component {
    componentDidMount(){
        this.props.methods.setSoundFilter('');
    }
    render() {
        let data=this.props.data;
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 son-container ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre}}></h3>
                {this.props.modele.sons.map((s,i)=>{
                    return <PlayerAudio
                    key={s.url}
                    playlist={this.props.playlist}
                    modele={this.props.modele}
                    methods={this.props.methods}
                    sound={s}
                    />
                })}
            </div>
        );
    }
}
export default Son;
