import React, { Component } from 'react';
import 'index.css';
import 'components/Presentation.scss';
import Html from 'components/Html.js';

class Presentation extends Component {
    render() {
        let data=this.props.data;
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre}}></h3>
                <div className="presentation-texte"><Html html={data.texte} navigate={this.props.goTo}/></div>
            </div>
        );
    }
}
export default Presentation;
