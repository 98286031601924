import React, { Component } from 'react';
import 'index.css';
import 'components/Image.scss';

class Image extends Component {
    constructor(props){
        super(props);
        this.state={
            loaded:false
        }
        this.showImage=this.showImage.bind(this);
    }
    showImage(){
        this.setState({loaded:true});
        if (this.props.onLoaded) this.props.onLoaded();
    }
    render() {
        let imgClass='image ' + this.props.className;
        if (this.state.loaded || this.props.direct) imgClass+=' loaded';
        return <img
            className={imgClass}
            onLoad={this.showImage}
            width={this.props.width}
            height={this.props.height}
            alt={this.props.alt}
            rel={this.props.rel}
            src={this.props.src}/>;
    }
}
export default Image;
