import React, { Component } from 'react';
import 'index.css';


import 'components/Videos.scss';


class Videos extends Component {
    componentDidMount(){
      console.log('videos',this.props.data);
      this.props.methods.showVideo();
      if (this.props.data.url_video) this.props.methods.playVideo(this.props.data.url_video);
    }
    render() {
        return (
            <div className="videos nopad">
            </div>
        );
    }
}
export default Videos;
