import Md5 from 'js-md5';
import _ from 'lodash';
class Playlist  {
    constructor(ed){
        this.players=[];
        this.ed=ed;
        this.handlePlay=this.handlePlay.bind(this);
        this.handlePause=this.handlePause.bind(this);
        this.handleEnded=this.handleEnded.bind(this);
        this.handleTimeupdate=this.handleTimeupdate.bind(this);
        this.handleLoadedMetadata=this.handleLoadedMetadata.bind(this);
        this.play=this.play.bind(this);
        this.pause=this.pause.bind(this);
        this.seek=this.seek.bind(this);
        this.getPlayer=this.getPlayer.bind(this);
    }
    getPlayer(url){
        return _.find(this.players,{url:url});
    }
    play(url){
        this.players.map((player)=>{
            if (player.url!==url) player.audio.pause()
            return null;
        });
        let player=_.find(this.players,{url:url});
        player.audio.play();
    }
    pause(url){
        let player=_.find(this.players,{url:url});
        player.audio.pause();
    }
    seek(url, time){
        let player=_.find(this.players,{url:url});
        player.audio.currentTime=time;
        if (player.audio.paused) this.play(player.url);
    }
    handlePlay(uuid){
        //console.log('play',uuid);
        let player=_.find(this.players,{uuid:uuid});
        this.ed.trigger('play-'+uuid);
        this.ed.trigger('play',player);
    }
    handlePause(uuid){
        //console.log('pause',uuid);
        this.ed.trigger('pause-'+uuid);
    }
    handleEnded(uuid){
        //console.log('ended',uuid);
        let player=_.find(this.players,{uuid:uuid});
        player.audio.currentTime=0;
        this.ed.trigger('ended',player);
    }
    handleTimeupdate(e,uuid){
        //console.log('timeupdate',e,uuid);
        this.ed.trigger('timeupdate-'+uuid,{
            currentTime:e.target.currentTime,
            position:e.target.currentTime/e.target.duration,
        });
    }
    handleLoadedMetadata(e,uuid){
        //console.log('metadata',e.target.duration,uuid);
        let player=_.find(this.players,{uuid:uuid});
        player.duration=e.target.duration;
        this.ed.trigger('loadedmetadata',player);
    }
    addSound(s){
        let player=_.clone(s);
        player.uuid=Md5(s.url);
        player.audio=document.createElement('audio');
        player.audio.setAttribute('id',player.uuid);
        player.audio.setAttribute('preload','metadata');
        player.audio.addEventListener('play',(e)=>this.handlePlay(player.uuid));
        player.audio.addEventListener('pause',(e)=>this.handlePause(player.uuid));
        player.audio.addEventListener('ended',(e)=>this.handleEnded(player.uuid));
        player.audio.addEventListener('timeupdate',(e)=>this.handleTimeupdate(e,player.uuid));
        player.audio.addEventListener('loadedmetadata',(e)=>this.handleLoadedMetadata(e,player.uuid));
        player.audio.setAttribute('src',s.url);
        this.players.push(player);
        document.body.appendChild(player.audio);
    }
};
export default Playlist;
