import React, { Component } from 'react';
import 'assets/bootstrap.min.custom.css';
import './App.scss';
import Router  from 'components/Router';
import { createBrowserHistory } from 'history';
//import Qs from 'qs';
import enterView from 'enter-view';
import axios from 'axios';
import FontFaceObserver from 'fontfaceobserver';
import _ from 'lodash';

import Parametres from 'utils/Parametres'
import Utils from 'utils/Utils.js';
import Playlist from 'utils/Playlist';
import ED from 'utils/EventDispatcher';
//routes
import Presentation from 'components/Presentation';
import Son from 'components/Son';
import Videos from 'components/Videos';
import ActionCulturelleRub from 'components/ActionCulturelleRub';
import ActionCulturelleAction from 'components/ActionCulturelleAction';
import Blog from 'components/Blog';
import Billet from 'components/Billet';
import Evenement from 'components/Evenement';
import Agenda from 'components/Agenda';
import Mentions from 'components/Mentions';
import Disques from 'components/Disques';
import Disque from 'components/Disque';

import PlayerAudio from 'components/PlayerAudio';
import VideoPlayers from 'components/VideoPlayers';
import Image from 'components/Image';
import Menu from 'components/Menu';
import XsMenu from 'components/XsMenu';
import EasingFunctions from 'components/EasingFunctions';

class App extends Component {
    constructor(props){
        super(props);
        this.state={
            templates:[
                {id:'presentation',component: Presentation },
                {id:'son',component: Son },
                {id:'videos',component: Videos },
                {id:'acRub',component: ActionCulturelleRub },
                {id:'acAction',component: ActionCulturelleAction },
                {id:'blog',component: Blog },
                {id:'billet',component: Billet },
                {id:'evenement',component: Evenement },
                {id:'agenda',component: Agenda },
                {id:'mentions',component: Mentions },
                {id:'disques',component: Disques },
                {id:'disque',component: Disque },
            ],
            routes:[],
            modele:{
                evts:[],
                menu:[],
                actions:[],
                groupes:[],
                sons:[],
                videos:[],
                mainUrl:'',
                pirenaUrl:'',
                soundFilter:'',
            },
            menu:[],
            slider:[],
            titre:'',
            image:'',
            screenSize:'xs',
            mainRatio:1,
            wait:0,
            audioPlayers:[],
            showVideo:false,
        }
        this.base='';
        this.history = createBrowserHistory({basename:this.base});
        this.resize=this.resize.bind(this);
        this.goTo=this.goTo.bind(this);
        this.goContenu=this.goContenu.bind(this);
        this.methods={
            setSoundFilter:this.setSoundFilter.bind(this),
            playNextSound:this.playNextSound.bind(this),
            playVideo:this.playVideo.bind(this),
            pauseVideo:this.pauseVideo.bind(this),
            selectVideo:this.selectVideo.bind(this),
            showVideo:this.showVideo.bind(this),
            hideVideo:this.hideVideo.bind(this),
        }
        this.handleRouteHasChanged=this.handleRouteHasChanged.bind(this);
        this.handleImageLoaded=this.handleImageLoaded.bind(this);
        this.handleSoundLoaded=this.handleSoundLoaded.bind(this);
        this.handleSoundPlay=this.handleSoundPlay.bind(this);
        this.ed=new ED();
        this.playlist=new Playlist(this.ed);
        //refs
        this.mainRef=React.createRef();
        this.enteteRef=React.createRef();
        this.enteteH3Ref=React.createRef();
    }
    setSoundFilter(filter){
        let modele=this.state.modele;
        modele.soundFilter=filter;
        this.setState({modele:modele});
    }
    playNextSound(){
        let selection=this.state.modele.sons;
        if (this.state.modele.soundFilter!=='') selection=_.filter(this.state.modele.sons,{disque:this.state.modele.soundFilter});
        let idx=_.findIndex(selection,{uuid:this.state.modele.selectedPlayer.uuid});
        if (idx>=0 && idx<selection.length-1) this.playlist.play(selection[idx+1].url);
        else this.playlist.play(selection[0].url);
    }
    handleSoundPlay(event,data){
        //console.log(event,data);
        let modele=this.state.modele;
        modele.selectedPlayer=data;
        this.setState({modele:modele});
        this.methods.pauseVideo();
        this.methods.hideVideo();
    }
    handleSoundLoaded(event,data){
        //console.log(event,data);
        let modele=this.state.modele;
        modele.sons.push(data);
        this.setState({modele:modele});
    }
    selectVideo(url){
        this.setState({selectedVideo:url,playingVideo:null});
    }
    playVideo(url){
        if (this.state.modele.selectedPlayer)
            this.playlist.pause(this.state.modele.selectedPlayer.url);
            setTimeout(()=>{
                let modele=this.state.modele;
                modele.selectedPlayer=null;
                this.setState({modele:modele});
                return null
            },100);
        this.setState({selectedVideo:url,playingVideo:url});
        let v=this.state.modele.videos.find((o)=>o.url===url);
        if (v) this.goTo(v.url_route);
    }
    pauseVideo(url){
        if (this.state.selectedVideo)
            this.setState({selectedVideo:url,playingVideo:null});
    }
    showVideo(url){
        this.setState({showVideo:true});
    }
    hideVideo(url){
        this.setState({showVideo:false});
    }
    componentDidMount() {
        this.ed.addEventListener('loadedmetadata',this.handleSoundLoaded);
        this.ed.addEventListener('play',this.handleSoundPlay);
        this.ed.addEventListener('ended',this.methods.playNextSound);
        this.enterview1=enterView({
        	selector: '.zone1',
        	enter: (el)=>null,
        	exit: (el)=>null,
        	progress: (el, progress)=>{
                if (this.enteteH3Ref.current) {
            	    this.enteteH3Ref.current.style.transform='translateX('+this.bouge(-50,0,EasingFunctions.easeOutQuart(progress))+'%)';
                    this.enteteH3Ref.current.style.fontSize=this.bouge(10,3,EasingFunctions.easeOutQuart(progress))+'vw';
                    this.enteteH3Ref.current.style.left=this.bouge(50,1,EasingFunctions.easeOutQuart(progress))+'%';
                    this.enteteH3Ref.current.style.bottom=this.bouge(7,0.5,progress*progress)+'vw';
                    if (progress===1) this.enteteH3Ref.current.style.bottom='unset';
                    this.enteteRef.current.style.transform='translateY('+(progress*50)+'%)';
                }
                if (progress<0.2) {
                    if (!this.state.hidePlayer) this.setState({hidePlayer:true});
                } else {
                    if (this.state.hidePlayer) this.setState({hidePlayer:false});
                }
                if (progress>=1) document.body.classList.add('entered');
                else document.body.classList.remove('entered');
                return null;
        	},
        	offset: 1,
        });
        axios({
            method: 'get',
            url: Parametres.apiUrl,
            withCredentials:true,
        }).then(res=> {
            let { billets,menu,groupes,disques,sons,videos,mainUrl,pirenaUrl,urlFb,routes,titre,image,credits }=res.data;
            this.sons=sons;
            Utils.shuffle(this.sons);
            urlFb=urlFb || 'https://www.facebook.com/';
            this.setState({
                routes,
                modele:{...this.state.modele,billets,menu,videos,groupes,disques,mainUrl,pirenaUrl,urlFb},
                titre,
                image,
                credits,
                wait:this.state.wait+1
            });
            this.sons.map((s)=>
                this.playlist.addSound(s)
            );
            return null;
        });
        axios({
            method: 'get',
            url: Parametres.evtsUrl,
            withCredentials:true,
        }).then(res=> {
            let {evts,routes,actions}=res.data;
            evts.sort(Utils.dateCompare);
            this.setState({
                routes:[...this.state.routes,...routes],
                modele:{...this.state.modele,evts,actions},
            });
            return null;
        });
        let font1 = new FontFaceObserver('GillSans-LightItalic');
        font1.load().then(()=>this.setState({wait:this.state.wait+1}));
        let font2 = new FontFaceObserver('GillSans');
        font2.load().then(()=>this.setState({wait:this.state.wait+1}));
        let font3 = new FontFaceObserver('Syncopate-Fab');
        font3.load().then(()=>this.setState({wait:this.state.wait+1}));
        let font4 = new FontFaceObserver('OpenSans-Regular');
        font4.load().then(()=>this.setState({wait:this.state.wait+1}));
        window.addEventListener('resize',this.resize);
        this.resize();
    }
    handleImageLoaded(){
        this.setState({wait:this.state.wait+1})
    }
    componentDidUpdate(){
        if (!this.state.started && this.state.wait>5){
            setTimeout(()=>document.styleSheets[0].insertRule('html {scroll-behavior: smooth;}', 0),1000);
        }
    }
    bouge(start,end,p){
        return (1-p)*start+p*end;
    }
    handleRouteHasChanged(route){
        if (!route.url.startsWith('/videos/') && this.state.showVideo) this.hideVideo();
        setTimeout(()=>{
            if (route.go_top) this.goTop();
            else this.goContenu();
            return;
        },500);
    }
    goTop(){
        window.scrollTo(0,0);
    }
    goContenu(){
        let nav=document.getElementById('main-nav');
        let rect=nav.getBoundingClientRect();
        window.scrollTo(0,this.mainRef.current.offsetTop-rect.height+1);
    }
    goTo(url){
        console.log(url);
        if ( url.indexOf('http://')>=0 || url.indexOf('https://')>=0)
            window.location.href=url;
        else
            this.history.push(url);
    }
    resize(){
        let W=window.innerWidth;
        let H=window.innerHeight;
        let widthClass='xs';
        if (W>767) widthClass='sm';
        if (W>991) widthClass='md';
        if (W>1200) widthClass='lg';
        let nav, rect;
        if (W<=767) {
            nav=document.getElementById('xs-nav');
            rect=nav.getBoundingClientRect();
        } else {
            nav=document.getElementById('main-nav');
            rect=nav.getBoundingClientRect();
        }
        H=H-rect.height;
        let w=W;
        let h=H;
        if (W/H>16/9) w=H*16/9;
        else h=W*9/16;
        this.setState({W:W,H:H,w:w,h:h,ml:(W-w)/2,mt:(H-h)/2,screenSize:widthClass});
    }
    render(){
        let appClass='app '+this.state.screenSize;
        if (this.state.wait>5) appClass+=' loaded';
        let router='';
        let audioPlayer='';
        let audioClass='main-audio-player';
        if (this.state.hidePlayer) audioClass+=' ko';
        if (this.state.modele.selectedPlayer) audioPlayer=<div className={audioClass}>
            {[this.state.modele.selectedPlayer].map((p)=>
                <PlayerAudio
                key={p.url}
                playlist={this.playlist}
                modele={this.state.modele}
                methods={this.methods}
                sound={p}
                small={true}
                />
            )}

        </div>;
        if (this.state.routes.length>0 && _.find(this.state.routes,{url:'/'}))
            router=<Router
            routes={this.state.routes}
            templates={this.state.templates}
            goTo={this.goTo}
            goTop={this.goTop}
            goContenu={this.goContenu}
            history={this.history}
            playlist={this.playlist}
            modele={this.state.modele}
            methods={this.methods}
            onRouteHasChanged={this.handleRouteHasChanged}
            />;
        return (
            <div className={appClass}>
                <div className="entete">
                    <div className="zone1"></div>
                    <div className="entete-image" ref={this.enteteRef}>
                        <Image className="img-responsive" src={this.state.image} width="2000" height="1200" alt={this.state.titre} onLoaded={this.handleImageLoaded}/>
                    </div>
                    <div className="credits">@ {this.state.credits}</div>
                    <h3 onClick={()=>this.goTo('/')} ref={this.enteteH3Ref} dangerouslySetInnerHTML={{__html:this.state.titre}}></h3>
                    <Menu
                    modele={this.state.modele}
                    goTop={this.goTop}
                    goTo={this.goTo}
                    />
                </div>
                <div className="content" ref={this.mainRef}>
                <XsMenu titre={this.state.titre} modele={this.state.modele} goTo={this.goTo}/>
                {router}
                </div>
                {audioPlayer}
                {this.state.wait>5 && <VideoPlayers
                showVideo={this.state.showVideo}
                h={this.state.h}
                w={this.state.w}
                ml={this.state.ml}
                mt={this.state.mt}
                H={this.state.H}
                playingVideo={this.state.playingVideo}
                selectedVideo={this.state.selectedVideo}
                methods={this.methods}
                goTo={this.goTo}
                videos={this.state.modele.videos}
                />}
            </div>
        );
    }
}

export default App;
