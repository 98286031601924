import React, { Component } from 'react';
import 'index.css';
import 'components/Html.scss';
import Utils from 'utils/Utils';
class Html extends Component {
    render() {
      let classes="html";
      if (this.props.inline) classes+=' inline'
      return <div className={classes}>{Utils.parseWithLinks(this.props.html,this.props.navigate)}</div>;
    }
}
export default Html;
