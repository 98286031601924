import React, { Component } from 'react';

import 'components/XsMenu.scss';
import MuiMenu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

class XsMenu extends Component {
    constructor(props){
        super(props);
        this.state={
            anchorEl:null,
        }
        this.handleMenuOpen=this.handleMenuOpen.bind(this);
        this.handleMenuClose=this.handleMenuClose.bind(this);
    }
    handleMenuOpen(e){
        this.setState({anchorEl:e.target});
    }
    handleMenuClose(){
        this.setState({anchorEl:null});
    }
    render() {
        return (
            <div id="xs-nav" className='visible-xs visible-sm visible-md xs-menu'>
                <h3 onClick={()=>this.goTo('/')} ref={this.enteteH3Ref} dangerouslySetInnerHTML={{__html:this.props.titre}}></h3>
                <div className="burger" onClick={this.handleMenuOpen}>
                <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path></svg>
                </div>
                 <MuiMenu
                id="simple-menu"
                anchorEl={this.state.anchorEl}
                keepMounted
                open={Boolean(this.state.anchorEl)}
                onClose={this.handleMenuClose}
                >
                {this.props.modele.menu.map((s,i)=>{
                    return <MenuItem className='xs-menu-item' key={s.url} onClick={()=>{this.props.goTo(s.url);this.handleMenuClose();}}><span dangerouslySetInnerHTML={{__html:s.titre}}></span></MenuItem>;
                })}
                </MuiMenu>
            </div>
        );
    }
}
export default XsMenu;
