/* eslint-disable vars-on-top, no-var, prefer-template */
var isRegExp = require('lodash/isRegExp');
var escapeRegExp = require('lodash/escapeRegExp');
var isString = require('lodash/isString');
var flatten = require('lodash/flatten');

const nbCapture=(re)=>{
  return (new RegExp(re.source + '|')).exec('').length - 1;
}
/**
 * Given a string, replace every substring that is matched by the `match` regex
 * with the result of calling `fn` on matched substring. The result will be an
 * array with all odd indexed elements containing the replacements. The primary
 * use case is similar to using String.prototype.replace except for React.
 *
 * React will happily render an array as children of a react element, which
 * makes this approach very useful for tasks like surrounding certain text
 * within a string with react elements.
 *
 * Example:
 * matchReplace(
 *   'Emphasize all phone numbers like 884-555-4443.',
 *   /([\d|-]+)/g,
 *   (number, i) => <strong key={i}>{number}</strong>
 * );
 * // => ['Emphasize all phone numbers like ', <strong>884-555-4443</strong>, '.'
 *
 * @param {string} str
 * @param {regexp|str} match Must contain a matching group
 * @param {function} fn
 * @return {array}
 */
function replaceString(str, match, fn) {
  if (str === '') {
    return str;
  } else if (!str || !isString(str)) {
    throw new TypeError('First argument to react-string-replace#replaceString must be a string');
  }

  var re = match;

  if (!isRegExp(re)) {
    re = new RegExp('(' + escapeRegExp(re) + ')', 'gi');
  }

  var result = str.split(re);
  // Apply fn to all odd elements
  let n=nbCapture(re);
  //console.log(n);
  let res=[];
  let k=0;
  for (var i = 0, length = result.length; i < length; i += n+1) {
    res.push(result[i]);
    k++;
    if (i<length-n-1) {
      let args=[];
      for(let j=0;j<n;j++){
        args.push(result[i+j+1]);
      }
      res.push(fn(args, k));
      k++;
    }
  }
  return res;
}

const reactStringReplace=(source, match, fn)=>{
  if (!Array.isArray(source)) source = [source];

  return flatten(source.map(function(x) {
    return isString(x) ? replaceString(x, match, fn) : x;
  }));
};

export { reactStringReplace };
