import React, { Component } from 'react';
import 'index.css';
import 'components/Footer.scss';

class Footer extends Component {
    render() {
        return <div className="footer">
            <div className="background"></div>
            <div className="contenu">
                <div className="row col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20">
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                            {this.props.modele.menu.map((item,i)=>{
                                let li=<li key={i}><span onClick={()=>this.props.goTo(item.url)} dangerouslySetInnerHTML={{__html:item.titre}}></span></li>;
                                if (item.url==='/videos/' && this.props.modele.videos.length===0) li='';
                                if (item.url==='/action-culturelle/' && this.props.modele.actions.length===0) li='';
                                if (item.url==='/blog/' && this.props.modele.billets.length===0) li='';
                                if (item.url==='/agenda/' && this.props.modele.evts.length===0) li='';
                                return li;
                            })}
                        </ul>
                    </div>
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                        <li><span onClick={()=>this.props.goTo(this.props.modele.pirenaUrl)}>{this.props.modele.pirenaUrl.replace('https://','').replace('/','')}</span></li>
                        <li>&nbsp;</li>
                        {this.props.modele.groupes.map((groupe,i)=>{
                            return (
                                <li key={i}><span onClick={()=>this.props.goTo(groupe.url)}>{groupe.url.replace('https://','').replace('/','')}</span></li>
                            );
                        })}
                        </ul>
                    </div>
                    <div className="plan col-xs-120 col-sm-40">
                        <ul className="footer-liste">
                            <li><span onClick={()=>this.props.goTo('/mentions_legales/')}>Mentions légales</span></li>
                            <li><a href={this.props.modele.urlFb} target="_blank" rel="noopener noreferrer"><div className="fb"></div></a>
</li>
                        </ul>

                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>;
    }
}
export default Footer;
