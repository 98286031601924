import React, { Component } from 'react';
import _ from 'lodash';
import 'index.css';
import 'components/Disque.scss';
import Html from 'components/Html.js';
import Image from 'components/Image';
import PlayerAudio from 'components/PlayerAudio';

class Disque extends Component {
    componentDidMount(){
        this.props.methods.setSoundFilter(this.props.data.titre);
    }
    render() {
        let data=this.props.data;
        let di=_.findIndex(this.props.modele.disques,{titre:data.titre});
        let dPrev, dNext, prev, next;
        if (di>0) dPrev=this.props.modele.disques[di-1];
        if (di<this.props.modele.disques.length-1) dNext=this.props.modele.disques[di+1];
        if (dPrev) prev=<span onClick={()=>this.props.goTo(dPrev.url)} className="pointer prev retour-ctrl">
            Suivant
        </span>;
        else prev=<span className="prev retour-ctrl">
            Suivant
        </span>;
        if (dNext) next=<span onClick={()=>this.props.goTo(dNext.url)} className="pointer next retour-ctrl">
            Précédent
        </span>;
        else next=<span className="next retour-ctrl">
            Précédent
        </span>;
        let extraits='';
        let sons=_.filter(this.props.modele.sons,{disque:data.titre});
        if (sons.length>0) extraits=<div>
            <h3>Extraits</h3>
            {_.filter(this.props.modele.sons,{disque:data.titre}).map((s,i)=>{
                return <PlayerAudio
                key={s.url}
                playlist={this.props.playlist}
                modele={this.props.modele}
                methods={this.props.methods}
                sound={s}
                />
            })}
        </div>;
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre+' <span>'+data.annee+'</span>'}}></h3>
                <div className="row liste">
                    <div className="col-xs-120 col-sm-60 col-md-40">
                        <div className="disque-seul">
                            <Image
                            className="img-responsive"
                            alt={data.titre}
                            src={data.image}
                            width="680"
                            height="600"/>
                        </div>
                        {data.production && <div className="disque-production">
                            {data.production}
                        </div>}
                        {data.lien && <div className="disque-lien">
                            <a href={data.lien} target="_blank" rel="noopener noreferrer"><strong>Acheter dans la boutique de Pirèna Immatèria</strong></a>
                        </div>}
                    </div>
                    <div className="col-xs-120 col-sm-60 col-md-80">
                        <div className="retour-btn">
                            <span onClick={()=>this.props.goTo('/disques/')} className="pointer retour-ctrl">
                                <span className="back">&lt;</span> Disques
                            </span>
                            {next}
                            {prev}
                        </div>
                        <Html html={data.texte} navigate={this.props.goTo}/>
                        {extraits}
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Disque;
