import React, { Component } from 'react';
import 'index.css';
import 'components/Disques.scss';
import Image from 'components/Image';

class Disques extends Component {
    render() {
        let data=this.props.data;
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre}}></h3>
                <div className="row liste">
                    {this.props.modele.disques.map((disque)=>{
                        return (
                            <div className="col-xs-120 col-sm-60 col-md-40" key={disque.titre}>
                                <div className="disque pointer" onClick={()=>this.props.goTo(disque.url)}>
                                    <Image
                                    className="img-responsive"
                                    alt={disque.titre}
                                    src={disque.image}
                                    width="680"
                                    height="600"/>
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Disques;
