import React, { Component } from 'react';
import 'index.css';
import 'components/TuileEvenement.scss';
import Image from 'components/Image';

class TuileEvenement extends Component {
    constructor(props){
        super(props);
        this.state={r:1};
        this.scale=this.scale.bind(this);
        this.tuileRef=React.createRef();
    }
    scale(){
        let t=this.tuileRef.current;
        let parent=t.parentNode;
        let tw=t.clientWidth;
        let pw=parent.clientWidth
            -parseFloat(window.getComputedStyle(parent, null).getPropertyValue('padding-left'))
            -parseFloat(window.getComputedStyle(parent, null).getPropertyValue('padding-right'));
        let r=pw/tw;
        parent.style.height=(t.clientHeight*r)+'px';
        this.setState({r:r});
    }
    componentDidMount(){
        this.scale();
        window.addEventListener('resize',this.scale);
    }
    componentWillUnmount(){
        window.removeEventListener('resize',this.scale);
    }
    render() {
        let e=this.props.evenement;
        let tuileClass='tuile-agenda pointer'
        if (e.id_agenda==='action-culturelle') tuileClass+=' ac';
        let dateFin='';
        if (e.plusieurs_jours) {
            tuileClass+=' plusieurs-jours';
            dateFin=<div className="date-fin">
                <div className="jour">{e.jour_fin}</div>
                <div className="mois">{e.mois_fin}</div>
                <div className="annee">{e.annee_fin}</div>
            </div>;
        }
        let horaire='';
        if (!e.journee_entiere) horaire=<div className="heure">{e.heure}</div>;
        let style={transform:'scale('+this.state.r+')'};
        let label=e.label || '';
        let intervenant='';
        e.tags.map((t)=>{
            if (t.indexOf('#intervenant:')!==-1) intervenant=t.replace('#intervenant:','').replace('_','');
            return null;
        });
        if (intervenant !=='') label+=" avec "+ intervenant;
        let titre=<span>&nbsp;</span>;
        if (e.titre) titre=e.titre;
        let sticker='';
        e.tags.map((t)=>{
            if (t.indexOf('#sticker:')===0) {
                sticker=<div className="sticker"><strong>{t.replace('#sticker:','')}</strong></div>
            }
            return null;
        });
        return (
        <div className="tuile-agenda-outer">
        <div className={tuileClass} onClick={()=>this.props.goTo(e.url)} ref={this.tuileRef} style={style}>
            <div className="date-debut">
                <div className="jour">{e.jour}</div>
                <div className="mois">{e.mois}</div>
                <div className="annee">{e.annee}</div>
            </div>
            {dateFin}
            <div className="type">{titre}</div>
            <div className="ville-lieu">
                <table>
                    <tbody>
                        <tr><td className="ville"><div>{e.lieu}</div></td></tr>
                        <tr><td className="lieu"><div>{e.lieu2}</div></td></tr>
                        <tr><td className="heure">{horaire}</td></tr>
                    </tbody>
                </table>
            </div>
            <Image className="img-responsive" alt={e.titre} src={e.image} width="600" height="300"/>
            <div className="agenda">{label}</div>
        </div>
        {sticker}
        </div>
        );
    }
}
export default TuileEvenement;
