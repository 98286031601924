import React, { Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import 'index.css';
//import Html from 'components/Html.js';
import TuileEvenement from 'components/TuileEvenement';

class Agenda extends Component {
    constructor(props){
        super(props);
        this.state={
            filter:'',
            pageArchive:0,
        }
        this.filter=this.filter.bind(this);
        this.archivesPrev=this.archivesPrev.bind(this);
        this.archivesNext=this.archivesNext.bind(this);
    }
    filter(g){
        this.setState({filter:g});
    }
    archivesPrev(g){
        let p=this.state.pageArchive;
        this.setState({pageArchive:Math.max(0,p-1)});
    }
    archivesNext(g){
        let p=this.state.pageArchive;
        let d=moment().format('YYYY/MM/DD');
        let archives=_.filter(this.props.modele.evts,(e)=>e.date_fin<d);
        this.setState({pageArchive:Math.min(archives.length/4,p+1)});
    }
    render() {
        let data=this.props.data;
        let d=moment().format('YYYY/MM/DD');
        let selection=_.filter(this.props.modele.evts,(e)=>e.date_fin>=d);
        let archives=_.filter(this.props.modele.evts,(e)=>e.date_fin<d);
        let archivesEvts='';
        let prev, next;
        if (archives.length>0) {
            if (this.state.pageArchive>0) prev=<span onClick={this.archivesPrev} className="pointer prev retour-ctrl">
                Suivant
            </span>;
            else prev=<span className="prev retour-ctrl">
                Suivant
            </span>;
            if (this.state.pageArchive<Math.floor(archives.length/4)) next=<span onClick={this.archivesNext} className="pointer next retour-ctrl">
                Précédent
            </span>;
            else next=<span className="next retour-ctrl">
                Précédent
            </span>;
            archivesEvts=<div className="row">
                <div className="col-xs-120">
                    <h3 className="titre">Événements passés</h3>
                    <div className="retour-btn">
                    {next}
                    {prev}
                    </div>
                </div>
                {_.filter(archives,(o,i)=> i>=archives.length-(this.state.pageArchive+1)*4 && i<archives.length-(this.state.pageArchive)*4 ).map((e,i)=>{
                    let res=[
                        <div key={e.id_eve} className="col-xs-60 col-sm-40 col-md-30">
                            <TuileEvenement
                            evenement={e}
                            goTo={this.props.goTo}
                            />
                        </div>
                    ];
                    if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs hidden-sm"></div>);
                    if (i%3===2) res.push(<div key={e.id_eve+'-2'} className="clearfix inter visible-sm"></div>);
                    if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                    return res;
                })}
                <div className="clearfix"></div>
            </div>;
        }
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.descriptif}}></h3>
                <div className="row">
                    {selection.map((e,i)=>{
                        let res=[
                            <div key={e.id_eve} className="col-xs-60 col-sm-40 col-md-30">
                                <TuileEvenement
                                evenement={e}
                                goTo={this.props.goTo}
                                />
                            </div>
                        ];
                        if (i%4===3) res.push(<div key={e.id_eve+'-1'} className="clearfix inter hidden-xs hidden-sm"></div>);
                        if (i%3===2) res.push(<div key={e.id_eve+'-2'} className="clearfix inter visible-sm"></div>);
                        if (i%2===1) res.push(<div key={e.id_eve+'-3'} className="clearfix inter visible-xs"></div>);
                        return res;
                    })}
                    <div className="clearfix"></div>
                </div>
                {archivesEvts}
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Agenda;
