import React, { Component } from 'react';
import 'index.css';
import 'components/Blog.scss';
import Html from 'components/Html.js';
import TuileBlog from 'components/TuileBlog';

class Blog extends Component {
    render() {
        let data=this.props.data;
        return (
            <div className="col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20 ">
                <h3 className="titre" dangerouslySetInnerHTML={{__html:data.titre}}></h3>
                <div className="paragraphe">
                    <Html html={data.texte} navigate={this.props.goTo}/>
                </div>
                <div className="row liste">
                    {this.props.modele.billets.map((billet)=>{
                        return (
                            <TuileBlog key={billet.url} billet={billet} goTo={this.props.goTo}/>
                        );
                    })}
                </div>
                <div className="clearfix"></div>
            </div>
        );
    }
}
export default Blog;
