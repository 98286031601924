import React, { Component } from 'react';
import 'index.css';
import 'components/PlayerAudio.scss';
import Vimeo from '@u-wave/react-vimeo';

class PlayerVideo extends Component {
    constructor(props){
        super(props);
        this.state={refresh:0};
    }
    render() {
        let v=this.props.video;
        let videoClass='video';
        if (this.props.selected) videoClass+=" selected";
        return (
            <div className={videoClass}>
                <Vimeo
                video={v.url}
                paused={!this.props.playing}
                responsive
                controls
                showPortrait={false}
                showTitle={false}
                showByline={false}
                autopause={false}
                onPlay={()=>this.props.methods.playVideo(v.url)}
                onPause={()=>this.props.methods.pauseVideo(v.url)}
                />
            </div>
        );
    }
}
export default PlayerVideo;
