import React, { Component } from 'react';
import 'index.css';
import 'swiper/css/swiper.min.css';
import Swiper from 'swiper';
import Image from 'components/Image';

import 'components/Slider.scss';

class Slider extends Component {
    constructor(props){
        super(props);
        this.state={
            nbImgLoaded:0
        };
        this.handleImgLoaded=this.handleImgLoaded.bind(this)
    }
    handleImgLoaded(){
        this.setState({nbImgLoaded:this.state.nbImgLoaded+1},()=>{
            if (this.state.nbImgLoaded===this.props.slider.length)
                this.props.onSliderLoaded();
        });
    }
    componentDidUpdate(){
        if (!this.slider && this.props.slider.length>0) {
            this.slider= new Swiper ('.swiper-container', {
                // Optional parameters
                slidesPerView:3,
                speed:700,
                loop: true,
                // If we need pagination
                pagination: {
                  el: '.swiper-pagination',
                },
                // Navigation arrows
                navigation: {
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev',
                },
                // And if we need scrollbar
                scrollbar: {
                  el: '.swiper-scrollbar',
                },
                breakpoints: {
                    // when window width is >= 320px
                    320: {
                        slidesPerView: 1,
                        spaceBetween: 0
                    },
                    // when window width is >= 480px
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 0
                    },
                    // when window width is >= 640px
                    1200: {
                        slidesPerView: 3,
                        spaceBetween: 0
                    }
                },
                on:{
                    click:(e)=>{
                        let url=e.target.parentNode.getAttribute('dataurl');
                        if (url) this.props.action(url);
                        return null;
                    }
                }
            });
        }
    }
    render() {
        let swiperClass="swiper-container col-xs-120 first";
        if (this.state.nbImgLoaded===this.props.slider.length) swiperClass+=' loaded'
        return (
            <div className={swiperClass}>
                <div className="swiper-wrapper">
                {this.props.slider.map((s,i)=>{
                    return (<div className="swiper-slide col-xs-40" key={i} dataurl={s.url}>
                        <Image
                        direct={true}
                        onLoaded={this.handleImgLoaded}
                        width="600"
                        height="350"
                        src={s.image}
                        className="img-responsive shadow"
                        alt={s.groupe}
                        />
                        <div className="caption">
                            <h3 className="titre" dangerouslySetInnerHTML={{__html:s.titre}}></h3>
                        </div>
                    </div>);
                })}
                </div>
                <div className="swiper-pagination"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
        );
    }
}
export default Slider;
