import React, { Component } from 'react';
import 'index.css';

import Image from 'components/Image';
import Slider from 'components/Slider';
import PlayerVideo from 'components/PlayerVideo.js';
import 'components/VideoPlayers.scss';


class VideoPlayers extends Component {
    render() {
        let videoStyle={};
        let videoContainerStyle={};
        if (this.props.showVideo && this.props.w!==0) {
            videoStyle={
                height:this.props.H,
            };
            videoContainerStyle={
                width:this.props.w,
                height:this.props.h,
                left:this.props.ml,
                top:this.props.mt
            };
        }
        let videoPlayers='';
        let menu='';
        let videoClass="video-players";
        if (this.props.showVideo) videoClass+=" show";
        if (this.props.playingVideo) videoClass+=" playing";
        if (this.props.playingVideo) videoClass+=" hide-menu";
        if (!this.props.showVideo && this.props.selectedVideo) videoClass+=" mini";
        if (this.props.videos.length>3) menu=<div className="videos-menu col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20">
            <h3 className="titre">Videos</h3>
            <div className="row liste">
            <Slider
            slider={this.props.videos}
            action={(url)=>this.props.methods.playVideo(url)}
            onSliderLoaded={()=>null}
            />
            </div>
        </div>;
        else if (this.props.videos.length>0) menu=<div className="videos-menu col-xs-120 col-sm-100 col-sm-offset-10 col-md-80 col-md-offset-20">
            <h3 className="titre">Videos</h3>
            <div className="row liste">
                {this.props.videos.map((s,i)=>
                    <div className="col-xs-40 menu-slide" key={i} onClick={()=>this.props.methods.playVideo(s.url)}>
                        <Image
                        direct={true}
                        onLoaded={this.handleImgLoaded}
                        width="600"
                        height="350"
                        src={s.image}
                        className="img-responsive shadow"
                        alt={s.groupe}
                        />
                        <div className="caption">
                            <h3 className="titre" dangerouslySetInnerHTML={{__html:s.titre}}></h3>
                        </div>
                    </div>
                )}
            </div>
        </div>;
        videoPlayers=<div className={videoClass} style={videoStyle}>
        {menu}
        <div className="video-players-container" style={videoContainerStyle}>
        {this.props.videos.map((v,i)=>{
            let selected=this.props.selectedVideo===v.url
                    || (!this.props.selectedVideo && i===0);
            let playing=this.props.playingVideo===v.url;
            return (
                <PlayerVideo
                video={v}
                key={v.url}
                selected={selected}
                playing={playing}
                methods={this.props.methods}
                />
            );
        })}
        </div>
        </div>;
        return videoPlayers;
    }
}
export default VideoPlayers;
